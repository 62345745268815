import React from 'react';
import Navbar from "../navbar";
import "./portfolio.scss"
import navigationLinksConfiguration from "../navigationLinksConfiguration"
import LinksDrawer from '../linksDrawer'
import Hero from "../sections/hero";
import {Container} from "@mui/joy";
import Aboutme from "../sections/aboutme";
import Techstack from "../sections/techstack";
import Experience from "../sections/experience";
import Getintouch from "../sections/getintouch";
import Footer from "../sections/footer";
import Copyright from "../sections/copyright";


function Portfolio() {
    return (
        <div className="portfolio-root">
            <Navbar navigationLinks={navigationLinksConfiguration}/>
            <LinksDrawer navigationLinks={navigationLinksConfiguration}/>
            <Hero/>
            <Container maxWidth={"xl"}>
                <Aboutme/>
                <Techstack/>
                <Experience/>
                <Getintouch/>
            </Container>
            <Footer/>
            <Copyright/>
        </div>
    );
}

export default Portfolio;
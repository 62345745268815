import React, {useEffect, useState} from 'react';
import './footer.scss';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import catdevlogo_img from "../../resources/Asset 8@0.5x-white.png";

function Footer() {
    const [navbarHeight, setNavbarHeight] = useState(0);
    useEffect(() => {
        // Get the CSS custom property value for navbar height
        const root = document.documentElement;
        const navbarHeightValue = getComputedStyle(root).getPropertyValue('--navbar-height');
        const navbarHeightInPixels = parseFloat(navbarHeightValue);
        setNavbarHeight(navbarHeightInPixels);
    }, [])


    return (
        <div className={'footer'}>
            <div className={'footer-container'}>
                <div className={'company'}>
                    <img src={catdevlogo_img} className={`title-row`} alt="CatDev Logo"/>
                    <div className={'company-data roboto-mono'}>
                        <p className={'company-name'}>CatDev Rafał Hrabia</p>
                        <p className={'company-description'}>Szczytowa 20/31, 20-562 Lublin,Poland<br/>
                            Email: contact@catdev.com.pl<br/>
                            NIP: PL7123448598<br/>
                            REGON: 524281200</p>
                    </div>
                </div>
                <div className={'footer-nav firaCode'}>
                    <div className={'nav-section'}>
                        <p className={'nav-title'}>
                            Nav
                        </p>
                        <div className={'nav-links'}>
                            <ScrollLink offset={-navbarHeight} to="aboutme" smooth={true} className={'nav-link'}>About me</ScrollLink>
                            <ScrollLink offset={-navbarHeight} to="technologies" smooth={true} className={'nav-link'}>Technologies</ScrollLink>
                            <ScrollLink offset={-navbarHeight} to="experience" smooth={true} className={'nav-link'}>Experience</ScrollLink>
                            <ScrollLink offset={-navbarHeight} to="contact" smooth={true} className={'nav-link'}>Contact</ScrollLink>
                        </div>
                    </div>
                    <div className={'nav-section'}>
                        <p className={'nav-title'}>
                            Socials
                        </p>
                        <div className={'nav-links'}>
                            <a className={'nav-link'} href={'https://www.linkedin.com/in/rafal-hrabia/'}>Linkedin</a>
                            <a className={'nav-link'} href={'https://github.com/Rafixese'}>Github</a>
                        </div>
                    </div>
                    <div className={'nav-section nav-section-double'}>
                        <div className={'nav-section'}>
                            <p className={'nav-title'}>
                                Contact us
                            </p>
                            <div className={'nav-links'}>
                                <a className={'nav-link'} href={'mailto:contact@catdev.com.pl'}>contact@catdev.com.pl</a>
                            </div>
                        </div>
                        <div className={'nav-section'}>
                            <p className={'nav-title'}>
                                Other
                            </p>
                            <div className={'nav-links'}>
                                <Link className={'nav-link'} to={'/privacypolicy'}>Privacy policy</Link>
                                <Link className={'nav-link'} to={'/termsandconditions'}>Terms of use</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
import React, {useEffect, useState} from 'react';
import Drawer from '@mui/joy/Drawer';
import List from '@mui/joy/List';
import {useSelector, useDispatch} from 'react-redux'
import {toggleDrawer} from './linksDrawerSlice'
import {RootState} from '../store'
import {NavigationLinkButtonProperties} from "./navigationLinksConfiguration";
import {ListItem, ListItemButton} from "@mui/joy";
import CatdevLogo from "./catdevLogo";
import './linksDrawer.scss'
import {scroller} from 'react-scroll';

interface LinksDrawerProps {
    navigationLinks: NavigationLinkButtonProperties[];
}

function LinksDrawer(props: LinksDrawerProps) {
    const [navbarHeight, setNavbarHeight] = useState(0);
    useEffect(() => {
        // Get the CSS custom property value for navbar height
        const root = document.documentElement;
        const navbarHeightValue = getComputedStyle(root).getPropertyValue('--navbar-height');
        const navbarHeightInPixels = parseFloat(navbarHeightValue);
        setNavbarHeight(navbarHeightInPixels);
    }, [])

    const dispatch = useDispatch()
    const linksDrawerIsOpen = useSelector((state: RootState) => state.linksDrawer.isOpen)
    const handleToggleDrawerEvent =
        (inOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            dispatch(toggleDrawer(inOpen));
        };

    const scrollToSection = (sectionId: string) => {
        scroller.scrollTo(sectionId, {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            offset: -navbarHeight
        });
    };

    const handleGoToLink = (linkTo: string) => {
        dispatch(toggleDrawer(false));
        scrollToSection(linkTo)
    }

    return (
        <div>
            <Drawer anchor="right" color={"primary"} variant="solid" invertedColors open={linksDrawerIsOpen}
                    onClose={handleToggleDrawerEvent(false)}>
                <div className={"links-drawer-logo"}>
                    <CatdevLogo imgSize="26px"/>
                </div>
                <List>
                    {props.navigationLinks.map((link) => (
                        <ListItem key={link.name}>
                            <ListItemButton onClick={() => {
                                handleGoToLink(link.linkTo)
                            }}>{link.name}</ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
}

export default LinksDrawer;
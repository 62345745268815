import React from 'react';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import Portfolio from "./components/pages/portfolio";
import PrivacyPolicy from "./components/pages/privacyPolicy";
import TermsAndConditions from "./components/pages/termsAndConditions";
import {store} from './store'
import {Provider} from 'react-redux'
import {CssVarsProvider} from "@mui/joy";
import theme from "./theme";
import ScrollToTop from "./components/scrollToTop";

function Router() {
    return (
        <CssVarsProvider theme={theme} defaultMode={"dark"}>
            <Provider store={store}>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Routes>
                        <Route path="/" element={<Portfolio/>}/>
                        <Route path="/privacypolicy" element={<PrivacyPolicy/>}/>
                        <Route path="/termsandconditions" element={<TermsAndConditions/>}/>
                    </Routes>
                </BrowserRouter>
            </Provider>
        </CssVarsProvider>
    );
}

export default Router;

import React from "react";
import "./hero.scss";
import layer1 from "../../resources/layers/Zasób 1.svg";
import layer2 from "../../resources/layers/Zasób 2.svg";
import layer3 from "../../resources/layers/Zasób 3.svg";
import layer4 from "../../resources/layers/Zasób 4.svg";
import layer5 from "../../resources/layers/Zasób 5.svg";
import layer6 from "../../resources/layers/Zasób 7.svg";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";

function Hero() {
	return (
		<ParallaxProvider>
			<div className="hero-section">
				<Parallax speed={-80}>
					<img src={layer6} alt="Layer 6" className="layer layer-6" />
				</Parallax>
				<Parallax speed={-40}>
					<img src={layer5} alt="Layer 5" className="layer layer-5 layer-moving" />
				</Parallax>
				<Parallax speed={-30}>
					<img src={layer4} alt="Layer 4" className="layer layer-4 layer-moving" />
				</Parallax>
				<Parallax speed={-20}>
					<img src={layer3} alt="Layer 3" className="layer layer-3 layer-moving" />
				</Parallax>
				<Parallax speed={-10}>
					<img src={layer2} alt="Layer 2" className="layer layer-2 layer-moving" />
				</Parallax>
				<Parallax speed={0}>
					<img src={layer1} alt="Layer 1" className="layer layer-1 layer-moving" />
				</Parallax>
			</div>
            <div className="gradient-transition"></div>
		</ParallaxProvider>
	);
}

export default Hero;

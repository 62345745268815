import React from 'react';
import './techstack.scss';
import TechstackTitle from "../techstackTitle";
import reactlogo from '../../resources/React.png';
import Cat, {CatProps, TailStyle} from "../componentCat/cat";
import pythonLogo from '../../resources/logo/python.png'
import djangoLogo from '../../resources/logo/django.png'
import Card, {ICard} from "../Card";
import webconLogo from '../../resources/logo/webcon.png'
import fastApiLogo from '../../resources/logo/fast api.png'
import cSharpLogo from '../../resources/logo/C#.png'
import kerasLogo from '../../resources/logo/keras.png'
import ciscoLogo from '../../resources/logo/cisco.png'
import ubuntuLogo from '../../resources/logo/ubuntu.png'
import freeCadLogo from '../../resources/logo/freecad.png'


function Techstack() {
    const catProps: CatProps = {
        height: '240px',
        tailStyle: TailStyle.default,
        headProps: {
            eyeProps: {
                pupilInfill: '90%',
                pupilTopOffset: '-10%',
                pupilLeftOffset: '-10%',
                pupilReflectionTopOffset: '-20%',
                pupilReflectionLeftOffset: '-20%',
            }
        }
    }

    const techCards: ICard[] = [
        {
            imageSrc: webconLogo,
            title: 'WebconBPS Development',
            description: 'Expertise in CRM systems and business process automation using WebconBPS.'
        },
        {
            imageSrc: fastApiLogo,
            title: 'Python & Fastapi',
            description: 'Developing small applications and extensions to enhance functionality and user experience.'
        },
        {
            imageSrc: cSharpLogo,
            title: 'C# Development',
            description: 'Utilizing C# to extend and enhance WebconBPS solutions.'
        },
        {
            imageSrc: kerasLogo,
            title: 'AI & Machine learning',
            description: 'Continuously exploring the latest trends and advancements in AI and ML. Using Python & Keras to develop ML models.'
        },
        {
            imageSrc: ciscoLogo,
            title: 'Basic Networking',
            description: 'Knowledgeable in setting up and managing networks, including working with routers and switches.'
        },
        {
            imageSrc: ubuntuLogo,
            title: 'Linux & Administration',
            description: 'Enthusiastic about experimenting with Linux, demonstrating a keen interest in system administration and server configuration.'
        },
        {
            imageSrc: freeCadLogo,
            title: '3D modeling and printing',
            description: 'Designing 3D models using FreeCAD, often for 3D printing projects.'
        }
    ]

    return (
        <div id={"technologies"} className={"tech-stack"}>
            <div className={"section-header"}>
                <TechstackTitle/>
                <div className={"section-animation"}>
                    <img className={"react-logo"} alt={"React logo"} src={reactlogo}/>
                    <Cat {...catProps}/>
                </div>
            </div>
            <div className={"current-tech"}>
                <div className={"tech-logos"}>
                    <img src={pythonLogo} alt={'Python Logo'}/>
                    <img src={djangoLogo} alt={'Django Logo'}/>
                    <img src={reactlogo} alt={'React Logo'}/>
                </div>
                <div className={"tech-description"}>
                    <p className={'firaCode description-header'}>WEB DEVELOPMENT</p>
                    <p className={'open-sans'}>I specialize in building dynamic user interfaces with React and
                        developing
                        robust APIs with Django REST Framework (DRF). I use PostgreSQL for scalable database management
                        and Docker for efficient containerization and deployment, ensuring high-performance, secure, and
                        user-friendly web applications.</p>
                </div>
            </div>
            <hr/>
            <div className={"section-other"}>
                <p className={'firaCode section-title'}>OTHER</p>
                <div className={'cards-container'}>
                    {techCards.map((tech, index) => (
                        <Card key={index} data={tech} />
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Techstack;
import React from 'react';
import './catEye.scss'


export interface EyeCatSettableProps {
    pupilInfill?: string
    pupilTopOffset?: string
    pupilLeftOffset?: string
    pupilReflectionTopOffset?: string
    pupilReflectionLeftOffset?: string
}

interface EyeProps extends EyeCatSettableProps {
    eyeDiameter: string
}

function CatEye({
                    eyeDiameter,
                    pupilInfill = '90%',
                    pupilTopOffset,
                    pupilLeftOffset,
                    pupilReflectionTopOffset,
                    pupilReflectionLeftOffset
                }: EyeProps) {
    const eyeInlineStyle: React.CSSProperties = {
        width: eyeDiameter,
        height: eyeDiameter
    }

    const pupilInlineStyle: React.CSSProperties = {
        width: pupilInfill,
        height: pupilInfill,
        top: pupilTopOffset || undefined,
        left: pupilLeftOffset || undefined,
    }

    const pupilReflectionInlineStyle: React.CSSProperties = {
        top: pupilReflectionTopOffset || undefined,
        left: pupilReflectionLeftOffset || undefined,
    }

    return (
        <div className={"eye"} style={eyeInlineStyle}>
            <div className={"pupil"} style={pupilInlineStyle}>
                {pupilReflectionTopOffset && pupilReflectionLeftOffset &&
                    <div className={"pupilReflection"} style={pupilReflectionInlineStyle}></div>}
            </div>
        </div>
    );
}

export default CatEye;
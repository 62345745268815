export class NavigationLinkButtonProperties {
    name: string;
    linkTo: string;
    active: boolean;

    constructor(name: string, linkTo: string, active: boolean=false) {
        this.name = name;
        this.linkTo = linkTo;
        this.active = active;
    }
}
const navigationLinksConfiguration = [
        new NavigationLinkButtonProperties("About me", "aboutme"),
        new NavigationLinkButtonProperties("Technologies", "technologies"),
        new NavigationLinkButtonProperties("Experience", "experience"),
        new NavigationLinkButtonProperties("Contact", "contact")
    ]

export default navigationLinksConfiguration;
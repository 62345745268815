import React, {useEffect, useState} from 'react';
import "./navigationLink.scss"
import {Link as ScrollLink} from 'react-scroll';
import "../variables.scss"

interface NavigationLinkProps {
    linkTo: string
    linkText: string
    active?: boolean
}

function NavigationLink(props: NavigationLinkProps) {
    const [navbarHeight, setNavbarHeight] = useState(0);
    useEffect(() => {
        // Get the CSS custom property value for navbar height
        const root = document.documentElement;
        const navbarHeightValue = getComputedStyle(root).getPropertyValue('--navbar-height');
        const navbarHeightInPixels = parseFloat(navbarHeightValue);
        setNavbarHeight(navbarHeightInPixels);
    }, [])

    return (
        <div className={"navigation-link roboto-mono" + (props.active ? " navigation-link-active" : "")}>
            <ScrollLink offset={-navbarHeight} to={props.linkTo} smooth={true}>{props.linkText}</ScrollLink>
        </div>
    );
}

export default NavigationLink;
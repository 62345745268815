import React from 'react';
import './copyright.scss'

function Copyright() {
    return (
        <div className={'copyright firaCode'}>
            <p>© 2024 CatDev Rafał Hrabia. All rights reserved.</p>
        </div>
    );
}

export default Copyright;
import React from 'react';
import './techstackTitle.scss'

function TechstackTitle() {
    const stackString: string = "STACK";
    return (
        <div className={"section-title firaCode"}>
            <div className={"title-big"}>TECH</div>
            <div className={"title-stack"}>
                {stackString.split('').map((char, index) => (
                    <div className={"stack-letter"} key={index}>{char}</div>
                ))}
            </div>
        </div>
    );
}

export default TechstackTitle;
import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export const linksDrawerSlice = createSlice({
    name: 'linksDrawerIsOpen',
    initialState: {
        isOpen: false
    },
    reducers: {
        toggleDrawer: (state, action:PayloadAction<boolean>) => {
            /**
             * Toggles drawer on and off based on payload parameter
             * true - opened
             * false - closed
             */
            console.log(`Toggling drawer with isOpen: ${action.payload}`)
            state.isOpen = action.payload
        }
    }
})

export const {toggleDrawer} = linksDrawerSlice.actions;
export default linksDrawerSlice.reducer;
import React from 'react';
import './catEar.scss'


export interface EarProps {
    earLeft: boolean
    earSize: number
}

function CatEar(props: EarProps) {
    const earOutsideInlineStyle: React.CSSProperties = {
        borderBottomWidth: `${props.earSize}px`,
        borderLeft: `${props.earSize / 2.0}px solid transparent`,
        borderRight: `${props.earSize / 2.0}px solid transparent`
    }

    const earInsideInlineStyle: React.CSSProperties = {
        borderBottomWidth: `${props.earSize}px`,
        borderLeft: `${props.earSize / 2.0}px solid transparent`,
        borderRight: `${props.earSize / 20.0}px solid transparent`
    }

    return (
        <div className={`ear ${props.earLeft ? 'ear-left' : 'ear-right'}`}>
            <div className={'ear-outside'} style={earOutsideInlineStyle}></div>
            <div className={'ear-inside'} style={earInsideInlineStyle}></div>
        </div>
    );
}

export default CatEar;
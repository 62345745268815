import React from 'react';
import './getintouch.scss'
import getintouchCat from '../../resources/contact_cat_no_bg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

function Getintouch() {
    return (
        <div id={'contact'} className="getintouch-section">
            <div className={"image-container"}>
                <img src={getintouchCat} alt={"Cat with phone and computer."}/>
            </div>
            <div className={'contact-container'}>
                <p className={'title firaCode'}>GET IN TOUCH!</p>
                <div className={'contact-info'}>
                    <div className={'contact-item'}>
                        <div className={'contact-item-img'}>
                            <FontAwesomeIcon icon={faLinkedin} />
                        </div>
                        <div className={'contact-item-link roboto-mono'}>
                            <a href={'https://www.linkedin.com/in/rafal-hrabia/'}>/in/rafal-hrabia/</a>
                        </div>
                    </div>
                    <div className={'contact-item'}>
                        <div className={'contact-item-img'}>
                            <FontAwesomeIcon icon={faGithub} />
                        </div>
                        <div className={'contact-item-link roboto-mono'}>
                            <a href={'https://github.com/Rafixese'}>/Rafixese</a>
                        </div>
                    </div>
                    <div className={'contact-item'}>
                        <div className={'contact-item-img'}>
                            <FontAwesomeIcon icon={faEnvelope} />
                        </div>
                        <div className={'contact-item-link roboto-mono'}>
                            <a href={'mailto:contact@catdev.com.pl'}>contact@catdev.com.pl</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Getintouch;
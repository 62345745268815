import React from 'react';
import './experience.scss'
import Card, {ICard} from "../Card";
import innflowLogo from "../../resources/logo/innflow.png"
import bottegaLogo from "../../resources/logo/bottega.png"
import CyberSkillerLogo from "../../resources/logo/cyberskiller.png"
import UMCSLogo from "../../resources/logo/umcs.png"

function Experience() {
    const experienceCards: ICard[] = [
        {
            imageSrc: innflowLogo,
            title: 'WebconBPS Developer',
            description: 'Focused on creating and implementing various CRM and business process automation systems using WebconBPS.'
        },
        {
            imageSrc: bottegaLogo,
            title: 'Application Developer',
            description: 'Spearheaded the development of an application centered around computer vision, showcasing my skills in innovative software solutions.'
        },
        {
            imageSrc: CyberSkillerLogo,
            title: 'Machine Learning Course Creator',
            description: 'Developed comprehensive ML courses, contributing to the field of educational technology and knowledge dissemination in machine learning.'
        },
        {
            imageSrc: UMCSLogo,
            title: 'Machine Learning Developer',
            description: 'Collaborated on a pioneering project to create ML models predicting network loads for over 1000 nodes for Orange. The focus was on network traffic originating from residential and business premises.'
        }
    ]
    return (
        <div id='experience' className={'experience-section'}>
            <div className={'section-title firaCode'}>
                EXPERIENCE
            </div>
            <div className={'experience-cards'}>
                {experienceCards.map((tech, index) => (
                    <Card key={index} data={tech}/>
                ))}
            </div>
        </div>
    );
}

export default Experience;
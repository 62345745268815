import { extendTheme } from '@mui/joy/styles';

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          50: '#D6D6D6',   // Lightest
          100: '#C2C2C2',
          200: '#AEAEAE',
          300: '#999999',
          400: '#858585',
          500: '#707070',   // Midpoint, slightly lighter than #2C2C2C
          600: '#565656',
          700: '#424242',
          800: '#2E2E2E',
          900: '#2C2C2C',   // Base color
        },
      },
    },
  },
});

// Then, pass it to `<CssVarsProvider theme={theme}>`.
export default theme;
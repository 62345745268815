import React, {useEffect, useRef, useState} from 'react';
import './catHead.scss'
import CatEye, {EyeCatSettableProps} from "./catEye";
import CatEar from "./catEar";

export interface HeadProps {
    eyeProps?: EyeCatSettableProps
}

function calculateEyeDiameter(headElement: HTMLElement): number {
    return headElement.offsetWidth * 0.22
}

function calculateEarSize(headElement: HTMLElement): number {
    return headElement.offsetWidth * 0.32
}

function CatHead(props: HeadProps) {
    const headRef = useRef<HTMLDivElement>(null);
    const [eyeDiameter, setEyeDiameter] = useState<number>(0);
    const [earSize, setEarSize] = useState<number>(0);

    useEffect(() => {
        const headElement = headRef.current;
        if (headElement) {
            let borderRadius = headElement.offsetWidth * 0.25
            headElement.style.borderRadius = `${borderRadius}px`

            setEyeDiameter(calculateEyeDiameter(headElement));
            setEarSize(calculateEarSize(headElement));
        }
    }, [headRef]);
    return (
        <div className="head-wrapper">
            <div ref={headRef} className={"head"}>
                <CatEye eyeDiameter={`${eyeDiameter}px`} {...props.eyeProps}/>
                <CatEye eyeDiameter={`${eyeDiameter}px`} {...props.eyeProps}/>
            </div>
            <CatEar earLeft={true} earSize={earSize}></CatEar>
            <CatEar earLeft={false} earSize={earSize}></CatEar>
        </div>

    );
}

export default CatHead;
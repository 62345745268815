import React, {useEffect, useRef, useState} from 'react';
import './cat.scss'
import CatHead from "./catHead";
import {HeadProps} from "./catHead";
import CatBody from "./catBody";
import CatTail from "./catTail";

export interface CatProps {
    width?: string;
    height?: string;
    tailStyle?: TailStyle;
    headProps?: HeadProps;
}

export enum TailStyle {
    default = 'default',
    wiggling = 'wiggling',
}

function calculateTailSize(catElement: HTMLElement): number {
    return catElement.offsetWidth * 0.42
}

function Cat({width, height, tailStyle = TailStyle.default, headProps}: CatProps) {
    const catInlineStyle: React.CSSProperties = {
        width: width || height || '100px',
        height: height || width || '100px',
    }
    const catRef = useRef<HTMLDivElement>(null);
    const [tailSize, setTailSize] = useState<number>(0);

    useEffect(() => {
        const catElement = catRef.current;
        if (catElement) {
            setTailSize(calculateTailSize(catElement))
        }
    }, [catRef]);

    return (
        <div ref={catRef} className={"cat"} style={catInlineStyle}>
            <CatHead {...headProps}/>
            <CatBody/>
            <CatTail tailSize={tailSize}/>
        </div>
    );
}

export default Cat;
import React from 'react';
import "./catdevLogo.scss";
import catdevlogo_img from "../resources/Asset 8@0.5x-white.png";

interface catdevLogoProps {
    imgSize: string,
    className?: string
}

function CatdevLogo(props: catdevLogoProps) {
    return (
        <img src={catdevlogo_img} className={`catdev-logo ${props.className}`} alt="CatDev Logo" style={{
            height: props.imgSize
        }}/>
    );
}

export default CatdevLogo;
import React from 'react';
import './aboutme.scss'
import profile_pic from '../../resources/rh_prof_pic.png'

function Aboutme() {
    return (
        <div className={"about-me"} id="aboutme">
            <div className={"title-row"}>
                <div className={"title-row"}>
                    <div className={"section-title firaCode"}>ABOUT ME</div>
                    <div className={"section-paragraph open-sans"}>
                        <p>Hi, I'm Rafał, a journey-driven developer with roots stretching back to my elementary school
                            days, where I first encountered the allure of coding with C++. My curiosity led me through
                            the intriguing world of Java and Android apps before university.</p>
                        <p>At university, a pivotal turn occurred. A dynamic group of friends and an inspiring teacher
                            introduced me to the fascinating world of Machine Learning. This experience wasn't just
                            academic; it involved crafting ML solutions for real-world projects, collaborating with
                            external companies through university ventures.</p>
                    </div>
                </div>
                <div className={"profile-picture-section"}>
                    <img alt={"Catdev developer"} src={profile_pic}/>
                    <div className={"back-square"}></div>
                </div>
            </div>
            <div className={"row open-sans"}>
                <p className={"section-paragraph"}>Post-graduation, my career took a professional leap as I joined the world of WebconBPS development.
                Here, I not only honed my skills in creating efficient CRM and business process automation systems but
                also dived into developing Python Fast API applications and C# extensions for WebconBPS. Now, my focus
                has shifted towards Web Development, particularly in React and Django REST framework.</p>

                <p className={"section-paragraph"}>My passion lies in creating solutions that simplify everyday challenges and automate processes, with a
                keen interest in the evolving trends of AI and Machine Learning. But there's more to me than just code.
                Away from the keyboard, I find joy in 3D printing and designing simple models in FreeCAD. Oh, and I'm a
                proud cat enthusiast!</p>

                <p className={"section-paragraph"}>My driving goal is continuous learning and improvement, aiming to craft tools and applications that
                genuinely help people and address their needs. Each line of code is a step towards this mission.</p>
            </div>
        </div>
    );
}

export default Aboutme;
import React from 'react';
import './Card.scss'

export interface ICard {
    imageSrc: string;
    title: string;
    description: string;
}
interface CardProps {
    className?: string;
    data: ICard;
}
function Card(props: CardProps) {
    return (
        <div className={`card ${props.className || ''}`}>
            <div className={'card-image-container'}>
                <img className={'card-image'} src={props.data.imageSrc} alt={props.data.title}/>
            </div>
            <div className={'card-text'}>
                <p className={'card-title firaCode'}>{props.data.title}</p>
                <p className={'card-description open-sans'}>{props.data.description}</p>
            </div>
        </div>
    );
}

export default Card;
import React, {useEffect, useRef, useState} from 'react';
import './catBody.scss'

function CatBody() {
    const pawRef = useRef<HTMLDivElement>(null);
    const [pawRadius, setPawRadius] = useState<number>(0)
    useEffect(() => {
        const pawElement = pawRef.current;
        if (pawElement) {
            let borderRadius = pawElement.offsetWidth * 0.85
            setPawRadius(borderRadius)
        }
    }, [pawRef]);

    const pawInlineStyles: React.CSSProperties = {
        borderRadius: `${pawRadius}px`
    }

    return (
        <div className={'cat-body'}>
            <div className={'torso'}/>
            <div className={'back'}/>
            <div ref={pawRef} className={'paw paw-left'} style={pawInlineStyles}/>
            <div ref={pawRef} className={'paw paw-right'} style={pawInlineStyles}/>
        </div>
    );
}

export default CatBody;
import React, {useEffect, useState} from 'react';
import {useSpring, animated} from '@react-spring/web';
import {easeCubicIn, easeCubicOut} from 'd3-ease';
import './catTail.scss';

interface CatTailProps {
    tailSize: number;
}

const CatTail: React.FC<CatTailProps> = ({tailSize}) => {
    const [initialized, setInitialized] = useState(false);

    const [springProps, api] = useSpring(() => ({
    tailEndX: tailSize * 0.3,
    tailEndY: tailSize * 0.3,
    config: { duration: 1000 },
  }));

  useEffect(() => {
    if (tailSize > 0 && !initialized) {
      api.start({
        to: async (next) => {
          while (true) {
            await next({
              tailEndX: tailSize * 0.6,
              tailEndY: tailSize * 0.1,
              config: { duration: 500, easing: easeCubicIn },
            });
            await next({
              tailEndX: tailSize * 0.9,
              tailEndY: tailSize * 0.3,
              config: { duration: 500, easing: easeCubicOut }, // linear easing for intermediate keyframe
            });
            await next({
              tailEndX: tailSize * 0.6,
              tailEndY: tailSize * 0.1,
              config: { duration: 500, easing: easeCubicIn }, // linear easing for intermediate keyframe
            });
            await next({
              tailEndX: tailSize * 0.3,
              tailEndY: tailSize * 0.3,
              config: { duration: 500, easing: easeCubicOut },
            });
          }
        },
        from: { tailEndX: tailSize * 0.3, tailEndY: tailSize * 0.3 },
        reset: true,
      });
      setInitialized(true);
    }
  }, [tailSize, initialized, api]);

    const getTailPath = (x: number, y: number) => `
    M 0,${tailSize * 0.925}
    L ${tailSize * 0.2},${tailSize * 0.925} 
    Q ${tailSize * 0.6},${tailSize * 0.925} ${tailSize * 0.6},${tailSize * 0.6}
    Q ${tailSize * 0.6},${tailSize * 0.2} ${x},${y}
  `;

    return (
        <svg
            className="cat-tail"
            width={tailSize}
            height={tailSize}
            viewBox={`0 0 ${tailSize} ${tailSize}`}
        >
            <animated.path
                fill="none"
                stroke="#121212"
                strokeWidth={tailSize * 0.15}
                strokeLinecap="round"
                d={springProps.tailEndX.to((x) => getTailPath(x, springProps.tailEndY.get()))}
            />
        </svg>
    );
};

export default CatTail;
